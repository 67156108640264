import React from "react"
import Layout from "./share/layout";

const Nivelacion = () => {
  return (
    <Layout>
      <iframe
        allowfullscreen="true"
        width="100%"
        frameBorder="0"
        src='https://upc-campeonas.vercel.app/'
        title=''

        style={{ height: '100vh' }}
      />
    </Layout>
  )
}

export default Nivelacion